import React from 'react';

const ImageGallery = ({ images }) => {
  return (
    <>
      {images.map((image, index) => (
        <img
          key={index}
          src={process.env.PUBLIC_URL + image.path}
          alt={image.alt}
          className="Unstructured-image"
          style={{
            width: image.width,
            left: image.left,
            top: image.top,
          }}
        />
      ))}
    </>
  );
};

export default ImageGallery;
