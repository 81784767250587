import React from "react";
import './SoundCloudPlayer2.css';

const SoundCloudPlayer2 = ({ isMobile }) => {
  const playerStyles = isMobile
    ? { left: '20%', top: '83%' } // Adjust the mobile styles as needed
    : { left: '11%', top: '90%' };

  return (
    <div className="SoundCloudPlayerContainer" style={playerStyles}>
      <iframe
        title="SoundCloud Player"
        className="soundCloudPlayerFrame"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1126827289&color=%23585a58&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
      ></iframe>
      <div className="soundCloudTrackInfo">
        <a
          href="https://soundcloud.com/erthsound"
          title="ERTH"
          target="_blank"
          rel="noreferrer"
          className="soundCloudLink"
        >
          {/* ERTH */}
        </a>{" "}
        ·{" "}
        <a
          href="https://soundcloud.com/erthsound/set-series-001"
          title="A time before ERTH - ZatuCast 01"
          target="_blank"
          rel="noreferrer"
          className="soundCloudLink"
        >
          {/* A Time Before ERTH - ZatuCast01 */}
        </a>
      </div>
    </div>
  );
};

export default SoundCloudPlayer2;