import React, { useState, useEffect } from 'react';
import './App.css';
import ImageGallery from './components/ImageGallery';
import SoundCloudPlayer1 from './components/SoundCloudPlayer1';
import SoundCloudPlayer2 from './components/SoundCloudPlayer2';
import TrendyText from './components/TrendyText';
import TrendyText2 from './components/TrendyText2'
import Logo from './components/Logo';
// import VideoPlayer1 from './components/VideoPlayer1';
import VerticalTextScroll from './components/VerticalTextScroll';

const desktopImages = [
  { path: '/images/image1.jpg', alt: 'Image 1', width: '34%', left: '12%', top: '5%' },
  { path: '/images/image2.jpg', alt: 'Image 2', width: '20%', left: '70%', top: '110%' },
  { path: '/images/image4.jpeg', alt: 'Image 4', width: '20%', left: '10%', top: '113%' },
  { path: '/images/image5.jpg', alt: 'Image 5', width: '35%', left: '54%', top: '27%' },
  { path: '/images/image6.png', alt: 'Image 6', width: '20%', left: '30%', top: '113.2%' },
  { path: '/images/image7.jpg', alt: 'Image 7', width: '30%', left: '57%', top: '101%' },
  { path: '/images/image9.jpg', alt: 'Image 9', width: '40%', left: '9%', top: '49%' },
];

const mobileImages = [
  // { path: '/images/image1.jpg', alt: 'Image 1', width: '70%', left: '15%', top: '7%' },
  { path: '/images/image2.jpg', alt: 'Image 2', width: '40%', left: '10%', top: '103%' },
  // { path: '/images/image4.jpeg', alt: 'Image 4', width: '60%', left: '10%', top: '50%' },
  // { path: '/images/image5.jpg', alt: 'Image 5', width: '60%', left: '10%', top: '60%' },
  { path: '/images/image6.png', alt: 'Image 6', width: '63%', left: '38%', top: '43%' },
  { path: '/images/image7.jpg', alt: 'Image 7', width: '65%', left: '30%', top: '109%' },
  { path: '/images/image8.jpg', alt: 'Image 7', width: '70%', left: '16%', top: '135%' },
  { path: '/images/image9.jpg', alt: 'Image 9', width: '90%', left: '5%', top: '3%' },
];

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check
    handleResize();

    // Listen to window resize events
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const images = isMobile ? mobileImages : desktopImages;

  return (
    <div className={`App ${isMobile ? 'mobile' : 'desktop'} pulse`}>
      <Logo isMobile={isMobile}/>
      <div className="App-header">
        <SoundCloudPlayer1 isMobile={isMobile} />
        <SoundCloudPlayer2 isMobile={isMobile} />
        {isMobile ? <TrendyText2 /> : <TrendyText />}
        <ImageGallery images={images} />
        {/* <VerticalTextScroll /> */}
        {/* <VideoPlayer1 /> */}
      </div>
    </div>
  );
}

export default App;
