// TrendyText.js

import React from 'react';
import './TrendyText.css';

function TrendyText() {
  return (
    <div className="TrendyText1">
      <p className="margin">A battlecry of a hawk make a dove fly and a tear dry</p>
      <p className="margin">Wonder why a lone wolf don't run with a clan</p>
      <p className="margin">Only trust your instincts and be one with the plan</p>
    </div>
  );
}

export default TrendyText;
