// SoundCloudPlayer1.js
import React from "react";
import './SoundCloudPlayer1.css';

const SoundCloudPlayer1 = ({ isMobile }) => {
  const playerStyles = isMobile
    ? { left: '5%', top: '27%' } // Adjust the mobile styles as needed
    : { left: '53.5%', top: '6%' };

  return (
    <div className="SoundCloudPlayerContainer" style={playerStyles}>
      <iframe
        title="SoundCloud Player"
        className="soundCloudPlayerFrame"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1658128017&color=%23585a58&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
      ></iframe>
      <div className="soundCloudTrackInfo">
        <a
          href="https://soundcloud.com/erthsound"
          title="ERTH"
          target="_blank"
          rel="noreferrer"
          className="soundCloudLink"
        >
          {/* ERTH */}
        </a>{" "}
        ·{" "}
        <a
          href="https://soundcloud.com/erthsound/erth-at-operator"
          title="ERTH Live at Operator Radio - 4th October 2023"
          target="_blank"
          rel="noreferrer"
          className="soundCloudLink"
        >
          {/* ERTH Live at Operator Radio - 4th October 2023 */}
        </a>
      </div>
    </div>
  );
};

export default SoundCloudPlayer1;
