// TrendyText.js

import React from 'react';
import './TrendyText2.css';

function TrendyText() {
  return (
    <div className="TrendyText">
      <p className="margin">To live is to die</p>
      <p className="margin">To live is to give</p>
      <p className="margin">To live is to be</p>
      <p className="margin">To live is to want</p>
      <p className="margin">To live is to search</p>
      <p className="margin">To live is to try</p>
      <p className="margin">To live is to pray</p>
      <p className="margin">To live is to serve</p>
      <p className="margin">To live is to love</p>
      <p className="margin">To live is to love</p>
      {/* <p className="margin">To live is to love</p> */}
      
    </div>
  );
}

export default TrendyText;
