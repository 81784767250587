// src/components/Logo.js
import React from 'react';
// import logo from '......./public/images/your-logo.png'; // Adjust the path accordingly

const logo_url = "/images/logo copy.png"

const Logo = ({ isMobile }) => {
  const logoStyles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    width: isMobile ? '375px' : '500px', // Adjust the width based on isMobile prop
    height: 'auto', // Maintain aspect ratio
  };

  return (
    <img
      src={process.env.PUBLIC_URL + logo_url}
      alt="Your Logo"
      style={logoStyles}
    />
  );
};

export default Logo;
