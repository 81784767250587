import React from 'react';
import './VerticalTextScroll.css';

function VerticalTextScroll() {
  return (
    <div className="VerticalTextScroll">
      <p>love yourself - love the world - love each other</p>
    </div>
  );
}

export default VerticalTextScroll;
